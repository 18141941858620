import { useRef } from 'react'
import { AcademicCapIcon, EyeIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'

import useRootData from '~/hooks/useRootData'
import useAutoPlayVideos from '~/hooks/useAutoPlayVideos'

import Button from '~/components/Button'
import SignUpForm from '~/modules/signup/components/SignUpForm'

export const HomePage = () => {
  const { currentUser, currentCustomer } = useRootData() || {}

  const signup = useRef<HTMLImageElement>(null)
  const scrollCtaIntoView = () => {
    if (signup.current) {
      signup.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  const { bannerRef, thumbnailRef } = useAutoPlayVideos()

  return (
    <div className="scroll-smooth">
      <div className="relative sm:pt-video-less-header-plus">
        <video
          ref={bannerRef}
          className="aspect-video w-full sm:absolute sm:bottom-0 sm:left-0 sm:right-0 sm:top-0"
          autoPlay
          muted
          loop
          playsInline
          poster="/new-homepage-banner.png"
          src="/homepage-background-pevan-and-sarah-intro.webm"
        />
        <div className="relative top-full z-20 flex flex-col items-center bg-white px-8 pb-8 pt-4 shadow-xl sm:absolute sm:left-1/2 sm:w-full sm:max-w-xl sm:-translate-x-1/2 sm:-translate-y-1/4 sm:rounded-2xl md:-translate-y-1/3 lg:-translate-y-2/3">
          <img src="/logo-text-dkbg-colour.svg" alt="Pevan & Sarah logo" className="w-24" />
          <p className="mb-4 text-center text-lg text-dark sm:text-xl md:text-2xl">
            Creating positive memories in the classroom
          </p>
          <Button size="lg" onClick={scrollCtaIntoView} text="Try Cub Club for free" intent="success" />
        </div>
      </div>
      <div className="relative z-10">
        <div className="logo-gradient flex items-center justify-center py-24 text-dark sm:min-h-screen sm:py-36">
          <div className="mx-auto max-w-md space-y-8 px-4 ">
            <div className="flex items-center space-x-8">
              <div>
                <AcademicCapIcon className="h-20 sm:h-24" />
              </div>
              <p className="text-xl">
                <strong>400+</strong> educational videos with matching printables
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <div>
                <EyeIcon className="h-20 sm:h-24" />
              </div>
              <p className="text-xl">
                <strong>80,000+</strong> monthly views
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <div>
                <LockClosedIcon className="h-20 sm:h-24" />
              </div>
              <p className="text-xl">
                <strong>safe</strong> and <strong>ad-free</strong> platform
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <video
            ref={thumbnailRef}
            preload="none"
            className="aspect-video w-full"
            muted
            loop
            playsInline
            poster="3d-thumbnails-loop.png"
            src="/homepage-background-moving-thumbnails.webm"
          />
          <div
            className={cx(
              'flex flex-col space-y-4 bg-white px-16 py-12 text-dark ',
              'md:absolute md:left-1/2 md:top-1/2 md:max-w-lg md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-3xl'
            )}>
            <p className="text-center text-lg">
              The Pevan & Sarah team are qualified teachers and experienced musicians who are passionate about creating{' '}
              <strong>fun, engaging and educational</strong> videos and resources for young learners.
            </p>
            <Button
              size="lg"
              onClick={scrollCtaIntoView}
              text="Try Cub Club for free"
              intent="success"
              className="mx-auto max-w-xs"
            />
          </div>
        </div>
        <div className="flex items-center justify-center bg-basePink px-16 py-8 text-white sm:h-half-screen">
          <div className="mr-auto flex max-w-5xl flex-col">
            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
              Pevan & Sarah are the BEST! I couldn't do each day in Prep without them.”
            </p>
            <div className="mt-6 max-w-max rounded-full bg-surfacePink px-8 py-3 text-black">
              <p className="font-bold">Rach</p>
              <p className="text-sm">Prep Teacher</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center bg-baseBlue px-8 py-8 text-right text-white sm:h-half-screen">
          <div className="ml-auto flex max-w-5xl flex-col items-end">
            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
              “My class and I absolutely adore Pevan & Sarah. We use their incredible videos in the classroom every
              day.”
            </p>
            <div className="mt-6 max-w-max rounded-full bg-surfaceBlue px-8 py-3 text-black">
              <p className="text-lg font-bold">Libby</p>
              <p className="text-sm ">Prep Teacher</p>
            </div>
          </div>
        </div>

        <div
          id="signup"
          ref={signup}
          className="flex flex-col items-center justify-center space-y-4 bg-baseOrange px-4 py-16 sm:min-h-screen">
          <img src="/cub-club-logo.png" alt="Cub Club logo" className="w-xxs" />

          <div className="space-y-0">
            {!currentCustomer && (
              <SignUpForm
                transparentPlanChooser
                className="mt-10 p-6"
                hideLoginLink={Boolean(currentUser)}
                defaultEmail={currentUser?.email}
              />
            )}
            {currentCustomer && (
              <div className="flex max-w-lg flex-col items-stretch space-y-8 rounded-2xl bg-white p-8 dark:bg-dark">
                <p className="text-lg font-bold">You already have an account.</p>
                <Button to="/cub-club/account/subscription" text="Manage your account" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
